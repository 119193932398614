import React, { Fragment, Suspense, useEffect } from 'react'
import loadable from '@loadable/component'
import GameBanner from './GameBanner/GameBanner'
const Metaverse = loadable(() => import('./Metaverse/Metaverse'));
const SalientGameFeatures = loadable(() => import('./SalientGameFeatures/SalientGameFeatures'));
const ClanTkn = loadable(() => import('./ClanTkn/ClanTkn'));
const StepGuide = loadable(() => import('./StepGuide/StepGuide'));
// const GameFaq = loadable(() => import('../Game/gamefaq'));
const ConqureMetaverse = loadable(() => import('./ConquerMetaverse/ConqureMetaverse'));
const HomeFaq = loadable(() => import('../Home/Faq/Faq'));

const Game = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);
  return (
    <Fragment>
      <GameBanner />
      <Suspense fallback={<div></div>}>
        <Metaverse />
        <ConqureMetaverse />
        <SalientGameFeatures />
        <ClanTkn />
        <StepGuide />
        <HomeFaq />
      </Suspense>

    </Fragment>
  )
}

export default Game