import React, { Fragment, Suspense, useEffect } from 'react'

import loadable from '@loadable/component'
import TokenBanner from './TokenBanner/TokenBanner';
const TokenAbout = loadable(() => import('./TokenAbout/TokenAbout'));
const TokenDistribution = loadable(() => import('./TokenDistribution/TokenDistribution'));
const TokenBuy = loadable(() => import('./TokenBuy/TokenBuy'));
const TokenDesclimer = loadable(() => import('./TokenDesclimer/TokenDesclimer'));
const HomeFaq = loadable(() => import('../Home/Faq/Faq'));
const ChartComponent = loadable(() => import('./chart'));


const Token = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);
  return (
    <Fragment>
      <TokenBanner />
      <Suspense fallback={<div></div>}>
        <TokenAbout />
        <TokenDistribution />
        <div className='container-fluid py-5 d-lg-block d-none'>
          <div className='row justify-content-center'>
            <div className='col-lg-10'>
              {/* <img src={Group} alt='Group' className='img-fluid' /> */}
              <ChartComponent />
            </div>
          </div>
        </div>
        <TokenBuy />
        {/* <TokenFaq /> */}
        <HomeFaq />
        <TokenDesclimer />
      </Suspense>

    </Fragment>
  )
}

export default Token