import React, { useEffect, useState } from 'react';
import { FaWindows } from "react-icons/fa";
import { BiLogoPlayStore } from "react-icons/bi";
import { BsHeadsetVr } from "react-icons/bs";
import ReactPlayer from 'react-player'

import Styles from './gamebanner.module.scss';


const GameBanner = () => {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    handleResize(); // Set initial state
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <section className={`${Styles.gameBanner}`}>
      <div className='row'>
        <div className=''>
          <div className={Styles.videoBg}>
            {/* <video  autoPlay loop muted>
        <source src={Snapsalvage} type="video/mp4" />
      </video> */}
            <ReactPlayer url={isMobile ? "/static/MobileView.webm" : "/static/snap-salvage.webm"} width='100%' height="100vh" playing loop muted style={{ objectFit: "cover" }}
              config={{
                file: {
                  attributes: {
                    preload: "auto",
                  },
                },
              }}
            />
          </div>

          <div className={`${Styles.overlayText} `}>
            <h2>EXPERIENCE OUR MULTI PLAYER SHOOTING GAME  <br /> INTEGRATED WITH BLOCKCHAIN</h2>
            <div className={Styles.buttons}>
              <button className={Styles.btn}><FaWindows size={"25"} />AVAILABLE ON <br /> WINDOWS 8+</button>
              <button className={Styles.btn}><BiLogoPlayStore size={"25"} />AVAILABLE ON  <br />ANDROID APK FILE</button>
              <button className={Styles.btn}><BsHeadsetVr size={"25"} />AVAILABLE ON <br /> VR SOFTWARE</button>
            </div>
          </div>
        </div>
      </div>

    </section>
  )
}

export default GameBanner