import React, { Fragment, Suspense } from 'react';
import loadable from '@loadable/component'
import { Outlet } from 'react-router-dom'

const Header = loadable(() => import('../Header/Header'));
const Footer = loadable(() => import('../Footer/Footer'));

const Layout = () => {
  return (
    <Fragment>
      <Outlet />
      <Suspense>
        <Header />
        <Footer />
      </Suspense>
    </Fragment>
  )
}

export default Layout