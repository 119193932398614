import React, { Fragment, Suspense, useEffect } from 'react';
import loadable from '@loadable/component'

import HomeBanner from './Banner/Banner';
const About = loadable(() => import('./About/About'));
const Token = loadable(() => import('./Token/Token'));
const Snapsalvage = loadable(() => import('./Snap/Snapsalvage'));
const SnapitLand = loadable(() => import('./Snapitland/SnapitLand'));
const HomeFaq = loadable(() => import('./Faq/Faq'));


const Home = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);
  return (
    <Fragment>
      <HomeBanner />
      <Suspense fallback={<div></div>}>
        <About />
        <Token />
        <Snapsalvage />
        <SnapitLand />
        <HomeFaq />
      </Suspense>
    </Fragment>

  );
};


export default Home;
