import React, { useEffect, useState } from 'react';
import styles from './banner.module.scss';
import Marquee from "react-fast-marquee";
import scroll1 from '../Assets/binance smartchain.png'
import scroll2 from '../Assets/ETH_logo.png';
import scroll3 from '../Assets/polygon.png';
import scroll4 from '../Assets/unity.png';
import scroll5 from '../Assets/meta.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const HomeBanner = () => {
  const [showVideo, setShowVideo] = useState(false);

  const handleVideoLoad = () => {
    setShowVideo(true);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleVideoLoad();
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <section className={`${styles.mainContent} container-fluid`}>

      {!showVideo && <div className={styles.imagSec}>

        <img src="/static/Rectangle.webp" alt="Main content" className={`${styles.fullImage} img-fluid`} width={1920} height={920} />
      </div>}
      {showVideo && <video className={styles.video} autoPlay muted loop playsInline>
        <source src="/static/Bnrvideo.webm" type="video/mp4" />
        support the video tag.
      </video>}

      <div className={`${styles.scroll} `}>
        <Marquee>
          <LazyLoadImage loading="lazy" src={scroll1} alt='' />
          <LazyLoadImage loading="lazy" src={scroll2} alt='' />
          <LazyLoadImage loading="lazy" src={scroll3} alt='' />
          <LazyLoadImage loading="lazy" src={scroll4} alt='' />
          <LazyLoadImage loading="lazy" src={scroll5} alt='' />
          <LazyLoadImage loading="lazy" src={scroll1} alt='' />
          <LazyLoadImage loading="lazy" src={scroll2} alt='' />
          <LazyLoadImage loading="lazy" src={scroll3} alt='' />
          <LazyLoadImage loading="lazy" src={scroll4} alt='' />
          <LazyLoadImage loading="lazy" src={scroll5} alt='' />
        </Marquee>
      </div>
    </section>
  );
};


export default HomeBanner;
